@import '../../colors';

aside.form {
  background-color: $colorGreen;
  flex: 1;
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 0 12px;
  text-align: center;

  @keyframes slideRight {
    0% { left: 40px; }
    100% { left: 0; opacity: 1; }
  }

  @keyframes slideMoreRight {
    0% { left: 100px; }
    100% { left: 0; opacity: 1; }
  }

  h1.selectTip {
    animation: slideRight 0.3s linear 0.55s 1 normal both;
    display: inline-block;
    font-size: 26px;
    margin-top: 10px;
    opacity: 0;
    position: relative;

    img {
      display: inline-block;
      height: 25px;
      vertical-align: bottom;
    }
  }

  p {
    animation: slideMoreRight 0.5s linear 0.75s;
    animation-fill-mode: both;
    background-color: $colorLightGreen;
    border: 1px solid; border-radius: 3px;
    font-size: 19px;
    margin: 15px 3px 3px;
    opacity: 0;
    padding: 5px 6px;
    position: relative;
  }

  button {
    animation: slideRight 0.4s linear 1.2s;
    animation-fill-mode: both;
    background-color: $colorDarkGreen;
    border: 1px solid; border-radius: 4px;
    cursor: pointer;
    font-family: "Maven Pro", sans-serif; font-size: 16px;
    margin: 3px;
    opacity: 0;
    padding: 6px 6px;
    position: relative;
    transition: 0.3s;

    &:hover {
      color: $colorLightGreen;
    }

    &.checked:hover {
      color: $colorDarkGreen;
    }

    &.checked {
      background-color: $colorLightGreen;
    }

    &:last-child {
      margin-bottom: 60px;
    }
  }

  @media (min-width: 320px) {

    button {
      padding: 6px 12px;
    }
  }
}
