@import '../../colors';

aside.meals {
  background-color: $colorDarkGreen;
  flex: 1;
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 0 12px;
  text-align: center;

  div.collapse, div.collapsing {
    margin: 0 auto;
    width: calc(100% - 6px);

    > button, > div {
      background-color: $colorLightGreen;
      border: 1px solid;
      border-radius: 3px;
      font-size: 19px;
      margin: 15px 0 0;
      padding: 5px 6px;
      width: 100%;
    }

    button {
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: $colorDarkGreen;
      }
    }

    div {

      %showImage {
        display: initial;
        width: 30px;
      }

      img {
        display: none;
      }

      &.fetching {

        img.fetching {
          @extend %showImage;
        }

        div {
          display: none;
        }
      }

      &.fetched {

        img.error {
          @extend %showImage;
        }
      }

      .links {

        a {
          text-decoration: underline;

          &:hover {
            color: $colorDarkGreen;
          }

          &:not(:first-child):before {
            color: initial;
            content: ',';
            display: inline-block;
            margin-right: 5px;
            text-decoration: none;
          }
        }
      }
    }
  }

  div.filler {
    height: 60px;
  }
}
