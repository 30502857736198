@import 'reset';
@import 'colors';
@import './components/App/app';
@import './components/Form/form';
@import './components/Meals/meals';

body {
  font-family: "Maven Pro", sans-serif;
  line-height: 1.2;
}

#root {
  background-color: $colorLightBrown;
}
