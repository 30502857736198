@import '../../colors';

@keyframes appear {
  0% { color: transparent; }
  100% { color: #fff; }
}

main {

  a {
    color: #000;
  }

  button, button:focus {
    outline: none;
  }

  header {
    background-color: $colorBrown;
    display: flex;
    justify-content: center;

    .title {
      animation: appear 1.1s ease-in 0s 1 normal both;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      font-size: 35px;
      font-weight: bold;
      height: 60px;
    }
  }

  div.container {
    display: flex;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;
  }
}
